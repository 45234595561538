<template>
  <Table
    :table-cols="tableCols"
    :table-data="tableData"
    :show-pagination="false"
  >
    <template v-slot="{ col, row }">
      <div v-if="col.prop === 'goodsBillType'" class="order-type">
        <span>
          {{ $CONSTANT.goodsTypeMap.O[row.type]||'-' }}
        </span>
        <span v-if="+row.type===2" class="time">
          {{ row.extMap.bidExpireTime|parseTime }}
        </span>
      </div>
      <div v-if="col.prop === 'lineName'">
        <TextToolTip :text="`${row.loadAddrName||'-'}-${row.unloadAddrName||'-'}`" placement="none" />
      </div>
      <div v-if="col.prop === 'price'">
        <span v-if="+row.type===1">
          <span v-if="+row.extMap.priceMiddle===1">
            {{ $parseNumber(row.extMap.priceOrder) }}{{ col.rulesObj[+row.priceUnit]||'-' }}
          </span>
          <span v-else>
            {{ $parseNumber(row.price) }}{{ col.rulesObj[+row.priceUnit]||'-' }}
          </span>
        </span>
        <span v-else>-</span>
      </div>
      <div v-if="col.prop === 'infoPrice'">
        <span v-if="+row.extMap.priceMiddle===1">
          {{ $parseNumber(row.price) }}{{ col.rulesObj[2]||'-' }}
        </span>
        <span v-else>-</span>
      </div>
      <div v-if="col.prop === 'needTax'">
        {{ row.extMap?col.rulesObj[+row.extMap.needTax]||'-':'-' }}
      </div>
      <div v-if="col.prop === 'plan'" class="plan-time">
        <div>装:<span>{{ row.loadEarlyTime|parseTime }}</span></div>
        <div>卸:<span>{{ row.unloadEarlyTime|parseTime }}</span></div>
      </div>
      <div v-if="col.prop === 'priority'">
        <span :class="col.rulesColorObj[row.priority]">
          {{ col.rulesObj[row.priority] }}
        </span>
      </div>
    </template>
  </Table>
</template>

<script>
import { parseTime } from '@/utils';
export default {
  filters: {
    parseTime,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tableCols: [
        { label: '货单编号', prop: 'goodsSerial', fixed: 'left', type: 'jump', evt: (data) => { this.toDetail(data); }, width: '185' },
        { label: '货单类型', prop: 'source', type: 'transition', rulesObj: this.$CONSTANT.goodsSourceMap.O, width: '80' },
        { label: '报车类型', prop: 'goodsBillType', type: 'customize', width: '125' },
        { label: '发单人', type: 'userInfoCard', width: 165 },
        { label: '发单时间', prop: 'createTime', type: 'filterTime', width: '165' },
        { label: '是否承接', prop: 'isRec', type: 'transition', rulesObj: { 1: '已承接', 0: '未承接', null: '-' }},
        { label: '推送小五', prop: 'isPushXw', type: 'transition', rulesObj: { 0: '未推送', 1: '已推送', null: '未推送' }},
        { label: '上报车数', prop: 'carCount' },
        { label: '已确认车数', prop: 'confirmCarCount' },
        { label: '优先级', prop: 'priority', type: 'customize', rulesObj: this.$CONSTANT.priorityMap, rulesColorObj: this.$CONSTANT.priorityColorMap },
        { label: '线路名称', type: 'customize', prop: 'lineName', width: '155' },
        { label: '产品名称', prop: 'goodsName' },
        { label: '运输数量(吨)', prop: 'total', type: 'numberTransition' },
        { label: '货主运价', prop: 'price', type: 'customize', rulesObj: this.$CONSTANT.transportPriceUnitMap.O },
        { label: '信息费', prop: 'infoPrice', type: 'customize', rulesObj: this.$CONSTANT.transportPriceUnitMap.O },
        { label: '是否含税', type: 'customize', prop: 'needTax', rulesObj: this.$CONSTANT.taxMap, width: '80' },
        { label: '需求车数', prop: 'truckNum', width: '100' },
        { label: '装卸计划时间', prop: 'plan', type: 'customize', width: '160' },
      ],
    };
  },
  methods: {
    toDetail({ id, type }) {
      this.$router.push(`/goodsOrder/supplyTask/detail?id=${id}&status=3&goodsBillType=${type || 1}`);
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
