<template>
  <!-- 承接记录（已报停） -->
  <div class="tab-list-container g-search-form-table">
    <search-form ref="form" :model="form" :count="9" label-width="auto" :collapse="true" @search="searchHandle" @reset="reset">
      <search-item label="" prop="customerUserName">
        <el-input v-model.trim="form.customerUserName" clearable placeholder="发单人姓名" />
      </search-item>
      <search-item label="" prop="customerName">
        <el-input v-model.trim="form.customerName" clearable placeholder="资质名称" />
      </search-item>
      <search-item label="" prop="loadAddrName">
        <el-input v-model.trim="form.loadAddrName" clearable placeholder="装货地址" />
      </search-item>
      <search-item label="" prop="unloadAddrName">
        <el-input v-model.trim="form.unloadAddrName" clearable placeholder="卸货地址" />
      </search-item>
      <template #hide>
        <search-item label="" prop="source">
          <el-select v-model="form.source" placeholder="货单类型" clearable>
            <el-option label="全部" :value="null" />
            <el-option v-for="item in $CONSTANT.goodsSourceMap.A" :key="item.value" :label="item.label" :value="+item.value" />
          </el-select>
        </search-item>
        <search-item label="" prop="goodsName">
          <el-input v-model.trim="form.goodsName" clearable placeholder="产品名称" />
        </search-item>
        <search-item label="" prop="goodsSerial">
          <el-input v-model.trim="form.goodsSerial" clearable placeholder="货单编号" />
        </search-item>
        <search-item label="" prop="type">
          <el-select v-model="form.type" placeholder="报车类型" clearable>
            <el-option label="全部" :value="null" />
            <el-option v-for="item in $CONSTANT.goodsTypeMap.A" :key="item.value" :label="item.label" :value="+item.value" />
          </el-select>
        </search-item>
        <!-- 发单时间 -->
        <search-item label="" prop="createTime">
          <el-date-picker
            v-model="createTime"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange"
          />
        </search-item>
      </template>
    </search-form>
    <div v-loading="loading" class="panel-wrapper">
      <div v-show="activeTab === 'list'" class="right-panel">
        <list-mode :table-data="listData" />
      </div>
    </div>
    <div
      v-if="listData.length>0"
      class="pb10 text-align-r"
    >
      <el-pagination
        layout="sizes, prev, pager, next, jumper, total"
        :total="pagination.total"
        :page-size="pagination.size"
        :current-page="pagination.page"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
  </div>
</template>

<script>
import ListMode from './list';
import supplyTask from '@/api/goodsOrder/supplyTask';
export default {
  components: { ListMode },
  data() {
    return {
      loading: false,
      form: {},
      activeTab: 'list',
      listData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 30,
      },
      createTime: null,
    };
  },
  methods: {
    toggleTab(tab) {
      this.activeTab = tab;
    },
    timeChange(time) {
      if (!time) {
        this.form.beginCreateTime = null;
        this.form.endCreateTime = null;
      } else {
        this.form.beginCreateTime = time[0];
        this.form.endCreateTime = time[1] + 86400000 - 1;
      }
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.form,
        state: '8,9,0', // 承接记录（已报停）8
        size: this.pagination.size,
        current: this.pagination.page,
      };
      supplyTask.orderGoodsList(params).then(res => {
        this.listData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    searchHandle() {
      this.pagination.page = 1;
      this.getList();
    },
    reset() {
      this.createTime = null;
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
