import request from '@/plugins/axios';
function pickTrue(obj) {
  const newObj = {
    ...obj,
  };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item] && newObj[item] !== 0) {
      delete newObj[item];
    }
  });
  return newObj;
}
// 详情左侧为组件 数据结构不一致 故转义同货单详情结构一致
export const getDetailType = {
  res: (d) => {
    return {
      ...d,
      goodsStop: d.extMap ? d.extMap.goodsStop || '-' : '-',
      tags: d.extMap.tags || '',
    };
  },
};
const prefix = '/auv';
export default {
  // 货单列表
  orderGoodsList: function(params) {
    return request.post(`${prefix}/order/goods/list`, pickTrue(params));
  },
  // 货单详情
  orderGoodsDetail: function(id) {
    return request.get(`${prefix}/order/goods/detail`, { id: id }, { showLoading: false }).then((res) => {
      res = getDetailType.res(res);
      return res;
    });
  },
  // 获取报价详情
  orderGoodsReportDetail: function(params) {
    return request.post(`${prefix}/order/goods/report/detail`, params);
  },
  // 修改上报记录信息
  orderGoodsReportUpdate: function(params) {
    return request.post(`${prefix}/order/goods/report/update`, params);
  },
  // 推送小五
  orderGoodsPushXw: function(inputForm) {
    return request.post(`${prefix}/order/goods/push/xw`, inputForm, { showLoading: true });
  },
  // 推送设置详情
  orderGoodsPushDetail: function(id) {
    return request.get(`${prefix}/order/goods/push/detail`, { id: id }, { showLoading: false }).then((res) => {
      return res;
    });
  },
  // 小五推送记录
  orderGoodsRecordXwList: function(params) {
    return request.post(`${prefix}/order/goods/record/xw/list`, params);
  },
  // 小五推送userId记录
  orderGoodsRecordXwUserList: function(params) {
    return request.post(`${prefix}/order/goods/record/xw/user/list`, params);
  },
  // 承接推送小五数量统计
  orderGoodsRecordXwCount: function(params) {
    return request.post(`${prefix}/order/goods/record/xw/count`, params);
  },
  // 推送平台优选
  orderGoodsPushPlatform: function(params) {
    return request.post(`${prefix}/order/goods/push/platform`, params);
  },
  // 货源承接——待提交、已确认列表
  orderCarQxList: function(params) {
    return request.post(`${prefix}/order/car/qx/list`, params);
  },
  // 前线取消改派列表
  orderCarQxChangeList: function(params) {
    return request.post(`${prefix}/order/car/qx/change/list`, params);
  },
  // 报车前车辆校验
  orderCarCheckReport: function(params) {
    return request.post(`${prefix}/order/car/check/report`, params);
  },
  // 批量提交车单货主确认
  orderOrderCarBatchSubmit: function(params) {
    return request.post(`${prefix}/order/car/batch/submit`, params);
  },
  // 批量取消车辆
  orderOrderCarBatchCancel: function(params) {
    return request.post(`${prefix}/order/car/batch/cancel`, params);
  },
  // 货主确认车单列表
  orderCarConfirmQxList: function(params) {
    return request.post(`${prefix}/order/car/confirm/qx/list`, params);
  },
  // 同意取消车辆推送货主
  orderCarCancel: function(params) {
    return request.post(`${prefix}/order/car/cancel`, params);
  },
  // 已取消列表（货主确认取消车单列表）
  orderCarCancelQxList: function(params) {
    return request.post(`${prefix}/order/car/cancel/qx/list`, params);
  },
  // 货源报价
  orderMiddlePriceReport: function(params) {
    return request.post(`${prefix}/order/middle/price/report`, params);
  },
  // 发送提醒小五推送车队
  orderGoodsRecordPushXw: function(params) {
    return request.post(`${prefix}/order/goods/record/push/xw`, params);
  },
  // 获取重复信息
  orderMiddleAuvRelCheck: function(params) {
    return request.post(`${prefix}/order/middle/auv/rel/check`, params);
  },
};
