<template>
  <div class="supply-task-wrap">
    <el-tabs v-model="tabPane">
      <el-tab-pane label="未承接" name="1" />
      <el-tab-pane label="承接中" name="2" />
      <el-tab-pane label="承接记录（已报停）" name="3" />
    </el-tabs>
    <div class="task-nav-wrap">
      <NotUndertake v-show="+tabPane===1" ref="notUndertake" />
      <Undertaking v-show="+tabPane===2" ref="undertaking" />
      <UndertakingRecords v-show="+tabPane===3" ref="undertakingRecords" />
    </div>
  </div>
</template>

<script>
import NotUndertake from './components/notUndertake';
import Undertaking from './components/undertaking';
import UndertakingRecords from './components/undertakingRecords';
export default {
  name: 'GoodsorderSupplytask',
  components: { NotUndertake, Undertaking, UndertakingRecords },
  data() {
    return {
      tabPane: '1',
    };
  },
  watch: {
    tabPane(val) {
      if (val === '1') {
        this.$refs.notUndertake.getList();
      } else if (val === '2') {
        this.$refs.undertaking.getList();
      } else if (val === '3') {
        this.$refs.undertakingRecords.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
